@import '~antd/dist/reset.css';

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
}

.mt-1rem {
  margin-top: 1rem;
}

.mb-24px {
  margin-bottom: 24px;
}

.mr-1rem {
  margin-right: 1rem;
}

.ml-1rem {
  margin-left: 1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.pt-1rem {
  padding-top: 1rem;
}

.pb-1rem {
  padding-bottom: 1rem;
}

.pr-2rem {
  padding-right: 2rem;
}

.pl-2rem {
  padding-left: 2rem;
}

.px-1rem {
  padding-left: 1rem;
  padding-right: 1rem;
}

.country-flag {
  height: 1em;
}

.d-flex {
  display: flex;
}

.d-flex-wrap {
  flex-wrap: wrap;
}

.g-0_5em {
  gap: 0.5em;
}

.g-1em {
  gap: 1em;
}

.d-flex-middle {
  align-items: center;
}

.d-flex-center {
  justify-content: center;
}

.d-flex-right {
  justify-content: flex-end;
}

.warning-color {
  color: red;
}

.filePage{
  height: 100%;
}

.new-file{
  color: red;
}

.sticky-row{
  position: sticky;
  bottom: 0;
  padding: 1rem 0 1rem 0;
  background-color: rgba(255, 255, 255, 0.7);
}

.iframe-height{
  width: 55%;
  height: 100%;
}