.group-name-container {
  display: flex;

  .group-colour {
    height: 1.4em;
    width: 1.4em;
    border-radius: 0.2em;
    margin-right: 1em;
    align-items: center;
  }
}
